import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../../styles"
import { Link } from "gatsby"

export const MenuContainer = styled.div`
  width: 100%;
  min-width: 275px;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 7px #00000008;
  padding-top: 35px;
  margin-bottom: 30px;
  @media (min-width: ${breakpoints.phablet}px) {
    width: 50%;
    max-width: 345px;
  }
  @media (min-width: ${breakpoints.tablet}px) {
    padding: 40px;
  }
`

export const HeadingWrapper = styled(Link)`
  display: flex;
  align-items: start;
  justify-content: flex-start;
  color: black;
  text-decoration: none;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const WrapperHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 20px;
`

export const SubMenuListWrapper = styled.div`
  margin-top: 30px;
`

export const SubMenuItemWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  text-decoration: none;
  color: ${colors.darkGrey};
  transition-duration: 0.2s;
  &:hover {
    color: ${colors.redWaskoll}
  }
`

export const Divider = styled.div`
  height: 3px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 5px;
  width: 30px;
  background: ${colors.redWaskoll};
`

export const MainContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  padding: 40px;
  font-family: Lato;
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.tablet}px) {
    justify-content: space-evenly;
    padding: 30px;
  }
`

export const MenuColumn = styled.div`
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MenuTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
  @media (min-width: ${breakpoints.tablet}px) {
    margin-left: 20px;
    margin-top: 0px;
  }
`

export const SubMenuTitle = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: 10px;
`

import styled from "@emotion/styled"
import { Link } from "gatsby"
import { breakpoints, colors } from "../styles"

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-family: Lato;
  @media (min-width: ${breakpoints.phablet}px) {
    padding-top: 50px;
  }
`

export const WrapperHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 20px;
`

export const ReturnMenu = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: black;
  transition-duration: 0.2s;
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 10px;
  &:hover {
    color: ${colors.redWaskoll}
  }
`

export const ReturnMenuTitle = styled.span`
  margin-left: 15px;
`

export const SubMenuContainer = styled.div`
  max-width: 800px;
  margin: auto;
  background: white;
  border-radius: 10px;
  padding: 25px;
  @media (min-width: ${breakpoints.phablet}px) {
    padding: 40px;
  }
`

export const HeadingTitleBold = styled.span`
  font-weight: 400;
`

import React, { useEffect, useState } from "react"
import { useParams } from "@reach/router"
import { Link, navigate } from "gatsby"

import {} from "./StyledAccountProfile"

import { AiOutlineArrowLeft } from "react-icons/ai"

import {
  HeadingTitleBold,
  MainWrapper,
  ReturnMenu,
  ReturnMenuTitle, SubMenuContainer,
  WrapperHeading
} from "../StyledAccount"

import { WrapperForm, FieldWrapper, Label, ButtonSubmit, ColumnFieldMarginR, DatePicker } from "./StyledAccountProfile"
import { Autocomplete, TextInput, toaster } from "evergreen-ui"
import { attributeProfileGender } from "../../shared/AccountMetaData"
import { FaCheck } from "react-icons/fa"
import useAuth from "../../../hooks/useAuth"
import { colors } from "../../styles"
import { uuid } from "uuidv4"
import { editUserProfile } from "../../../utils/userHelpers"
import { FieldContainerFull } from "./StyledAccountProfile"
import { FieldWrapperColumn } from "./StyledAccountProfile"

export default ({currentUser, successCallback}) => {
  const [currentProfile, setCurrentProfile] = useState({
    id: "",
    street_1: "",
    street_2: "",
    zip: "",
    city: "",
    country: ""
  })

  const [isError, setError] = useState({})

  useEffect(() => {
    if (currentUser.id) setCurrentProfile({
      id: currentUser.id,
      street_1: currentUser.street_1 || '',
      street_2: currentUser.street_2 || '',
      zip: currentUser.zip || '',
      city: currentUser.city || '',
      country: currentUser.country || '',
    })
  }, [currentUser])

  const handleChange = (name, value) => {
    setCurrentProfile({
      ...currentProfile,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    editUserProfile(currentProfile)
      .then(() => {
        successCallback()
      })
      .catch((err) => {
        console.error("EDIT SHIPPING: ERROR ", err)
      })
  }

  const renderProfileForm = () => {
    return (
      <WrapperForm>
        <FieldWrapperColumn>
          <FieldContainerFull>
            <Label>Adresse</Label>
            <TextInput
              height={42}
              width="100%"
              isInvalid={!!isError.street_1}
              value={currentProfile.street_1}
              onChange={e => handleChange("street_1", e.target.value)}
            />
          </FieldContainerFull>
          <FieldContainerFull>
            <Label>Complément d'adresse</Label>
            <TextInput
              height={42}
              width="100%"
              isInvalid={!!isError.street_2}
              value={currentProfile.street_2}
              onChange={e => handleChange("street_2", e.target.value)}
            />
          </FieldContainerFull>
          <FieldContainerFull>
            <Label>Code postal</Label>
            <TextInput
              height={42}
              width="100%"
              isInvalid={!!isError.zip}
              value={currentProfile.zip}
              onChange={e => handleChange("zip", e.target.value)}
            />
          </FieldContainerFull>
          <FieldContainerFull>
            <Label>Ville</Label>
            <TextInput
              height={42}
              width="100%"
              isInvalid={!!isError.city}
              value={currentProfile.city}
              onChange={e => handleChange("city", e.target.value)}
            />
          </FieldContainerFull>
          <FieldContainerFull>
            <Label>Pays</Label>
            <TextInput
              height={42}
              width="100%"
              isInvalid={!!isError.country}
              value={currentProfile.country}
              onChange={e => handleChange("country", e.target.value)}
            />
          </FieldContainerFull>
        </FieldWrapperColumn>

        <ButtonSubmit onClick={(e) => handleSubmit(e)}>
          <FaCheck color={colors.darkWaskoll}/>
          <span style={{marginLeft: "10px"}}>Sauvegarder les modifications</span>
        </ButtonSubmit>
      </WrapperForm>

    )
  }


  return (
    <MainWrapper>
      <SubMenuContainer>
        <WrapperHeading>
          <ReturnMenu to={"/account/"}>
            <AiOutlineArrowLeft size={15} color={"black"}/>
            <ReturnMenuTitle>Revenir en arrière</ReturnMenuTitle>
          </ReturnMenu>
          <span>Modifier votre <HeadingTitleBold>adresse de livraison</HeadingTitleBold></span>
        </WrapperHeading>
        {renderProfileForm()}
      </SubMenuContainer>

    </MainWrapper>
  )
}

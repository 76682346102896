import React, { useEffect, useState } from "react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import {
  HeadingTitleBold,
  MainWrapper,
  ReturnMenu,
  ReturnMenuTitle, SubMenuContainer,
  WrapperHeading
} from "../StyledAccount"

import {
  WrapperForm,
  FieldWrapper,
  Label,
  ButtonSubmit,
  FieldContainerFull,
  FieldWrapperColumn,
  WrapperPassword,
  IconPassword
} from "./StyledAccountProfile"
import { Alert, Spinner, TextInput, toaster } from "evergreen-ui"
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa"
import { colors } from "../../styles"
import { editUserPassword } from "../../../utils/userHelpers"

export default ({successCallback}) => {
  const [currentProfile, setCurrentProfile] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  })

  const [isError, setError] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleChange = (name, value) => {
    setCurrentProfile({
      ...currentProfile,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    if (currentProfile.newPassword !== currentProfile.newPasswordRepeat) {
      setLoading(false)
      setError({newPassword: "Les mots de passes ne correspondent pas"})
    }
    else {
      editUserPassword(currentProfile)
        .then(() => {
          setLoading(false)
          successCallback()
          if (isError.oldPassword || isError.newPassword) setError({})
          setCurrentProfile({
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
          })
        })
        .catch(err => {
          console.error(err.code)
          setError({})
          if (err.code === "InvalidParameterException") setError({oldPassword: "Votre ancien mot de passe est incorrect"})
          setLoading(false)
        })
    }
  }

  const handlerPassword = (e) => {
    e.preventDefault()
    setPasswordVisible(!passwordVisible)
  }

  const renderProfileForm = () => {
    return (
      <WrapperForm>
        {isError.oldPassword && (
          <Alert
            intent="danger"
            title={isError.oldPassword}
          />
        )}
        {isError.newPassword && (
          <Alert
            intent="danger"
            title={isError.newPassword}
          />
        )}

        <FieldWrapperColumn>
          <FieldContainerFull>
            <Label>Ancien mot de passe</Label>
            <WrapperPassword>
              <IconPassword onClick={handlerPassword}>
                {!passwordVisible ? <FaEye size={15}/> : <FaEyeSlash size={15}/> }
              </IconPassword>
            </WrapperPassword>
            <TextInput
              name="password"
              type={passwordVisible ? "text": "password"}
              height={42}
              paddingR={40}
              width="100%"
              isInvalid={!!isError.oldPassword}
              value={currentProfile.oldPassword}
              onChange={e => handleChange("oldPassword", e.target.value)}
            />
          </FieldContainerFull>
          <FieldContainerFull>
            <Label>Nouveau mot de passe</Label>
            <WrapperPassword>
              <IconPassword onClick={handlerPassword}>
                {!passwordVisible ? <FaEye size={15}/> : <FaEyeSlash size={15}/> }
              </IconPassword>
            </WrapperPassword>
            <TextInput
              name="password"
              type={passwordVisible ? "text": "password"}
              height={42}
              width="100%"
              isInvalid={!!isError.newPassword}
              value={currentProfile.newPassword}
              onChange={e => handleChange("newPassword", e.target.value)}
            />
          </FieldContainerFull>
          <FieldContainerFull>
            <Label>Confirmer le nouveau mot de passe</Label>
            <WrapperPassword>
              <IconPassword onClick={handlerPassword}>
                {!passwordVisible ? <FaEye size={15}/> : <FaEyeSlash size={15}/> }
              </IconPassword>
            </WrapperPassword>
            <TextInput
              name="password"
              type={passwordVisible ? "text": "password"}
              height={42}
              width="100%"
              isInvalid={!!isError.newPassword}
              value={currentProfile.newPasswordRepeat}
              onChange={e => handleChange("newPasswordRepeat", e.target.value)}
            />
          </FieldContainerFull>
        </FieldWrapperColumn>
        {isLoading ? (
          <Spinner marginX={"auto"} marginY={20} size={38} color={"black"}/>
        ) : (
          <ButtonSubmit onClick={(e) => handleSubmit(e)}>
            <FaCheck color={colors.darkWaskoll}/>
            <span style={{marginLeft: "10px"}}>Sauvegarder les modifications</span>
          </ButtonSubmit>
        )}


      </WrapperForm>

    )
  }


  return (
    <MainWrapper>
      <SubMenuContainer>
        <WrapperHeading>
          <ReturnMenu to={"/account/"}>
            <AiOutlineArrowLeft size={15} color={"black"}/>
            <ReturnMenuTitle>Revenir en arrière</ReturnMenuTitle>
          </ReturnMenu>
          <span>Modifier votre <HeadingTitleBold>mot de passe</HeadingTitleBold></span>
        </WrapperHeading>
        {renderProfileForm()}
      </SubMenuContainer>

    </MainWrapper>
  )
}

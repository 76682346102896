import React, { useEffect, useState } from "react"
import { Redirect, Router } from '@reach/router'
import { isLoggedIn, SignIn, SignUp } from "../../utils/auth"
import AccountMenu from '../../components/Account/Menu'
import AccountProfile from '../../components/Account/Profile/EditProfile'
import AccountEmail from '../../components/Account/Profile/EditEmail'
import AccountPassword from '../../components/Account/Profile/EditPassword'
import AccountShipping from '../../components/Account/Profile/EditShipping'
import useAuth from "../../hooks/useAuth"

import styled from "@emotion/styled"
import { toaster } from "evergreen-ui"

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  margin: auto;
  padding: 40px;
`


export default ({ location }) => {
  const { currentUser, setUserSession} = useAuth()

  const handlerSuccessEdit = () => {
    toaster.success(
      "Vos modifications ont bien été enregistrées"
    )
    setUserSession()
  }

  return isLoggedIn() ? (
    <Router basepath="/account">
      <AccountProfile currentUser={currentUser} successCallback={handlerSuccessEdit} path="/edit-profile"/>
      <AccountEmail currentUser={currentUser} successCallback={handlerSuccessEdit} path="/edit-email"/>
      <AccountPassword successCallback={handlerSuccessEdit} path="/edit-password"/>
      <AccountShipping currentUser={currentUser} successCallback={handlerSuccessEdit} path="/edit-shipping"/>
      <AccountMenu path="/*"/>
    </Router>
  ) : (
    <Redirect
      noThrow
      from="/account/"
      to="/"
    />
  )
}

import React, { useEffect, useState } from "react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import {
  HeadingTitleBold,
  MainWrapper,
  ReturnMenu,
  ReturnMenuTitle, SubMenuContainer,
  WrapperHeading
} from "../StyledAccount"

import { WrapperForm, FieldWrapper, Label, ButtonSubmit } from "./StyledAccountProfile"
import { Alert, Spinner, TextInput, toaster } from "evergreen-ui"
import { FaCheck } from "react-icons/fa"
import { colors } from "../../styles"
import { editUserEmail } from "../../../utils/userHelpers"
import useAuth from "../../../hooks/useAuth"

export default ({currentUser, successCallback}) => {

  const [currentProfile, setCurrentProfile] = useState({
    id: "",
    email: "",
  })

  const [isError, setError] = useState({})
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (currentUser.id) setCurrentProfile({
      id: currentUser.id,
      email: currentUser.email,
    })
  }, [currentUser])

  const handleChange = (name, value) => {
    setCurrentProfile({
      ...currentProfile,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    editUserEmail(currentProfile)
      .then(() => {
        setLoading(false)
        successCallback()
        if (isError.email) setError({})
      })
      .catch(err => {
        console.error(err.code)
        if (err.code === "AliasExistsException") setError({email: "Cette adresse e-mail est déjà utilisée"})
        if (err.code === "InvalidParameterException") setError({email: "Cette adresse e-mail est invalide"})
        setLoading(false)
      })
  }

  const renderProfileForm = () => {
    return (
      <WrapperForm>
        {isError.email && (
          <Alert
            intent="danger"
            title={isError.email}
          />
        )}

        <FieldWrapper>
          <div>
            <Label>Email</Label>
            <TextInput
              type={"email"}
              height={42}
              width="100%"
              isInvalid={!!isError.email}
              value={currentProfile.email}
              onChange={e => handleChange("email", e.target.value)}
            />
          </div>
        </FieldWrapper>
        {isLoading ? (
          <Spinner marginX={"auto"} marginY={20} size={38} color={"black"}/>
        ) : (
          <ButtonSubmit onClick={(e) => handleSubmit(e)}>
            <FaCheck color={colors.darkWaskoll}/>
            <span style={{marginLeft: "10px"}}>Sauvegarder les modifications</span>
          </ButtonSubmit>
        )}


      </WrapperForm>

    )
  }


  return (
    <MainWrapper>
      <SubMenuContainer>
        <WrapperHeading>
          <ReturnMenu to={"/account/"}>
            <AiOutlineArrowLeft size={15} color={"black"}/>
            <ReturnMenuTitle>Revenir en arrière</ReturnMenuTitle>
          </ReturnMenu>
          <span>Modifier votre <HeadingTitleBold>adresse email</HeadingTitleBold></span>
        </WrapperHeading>
        {renderProfileForm()}
      </SubMenuContainer>

    </MainWrapper>
  )
}

import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"

import { colors } from "../../styles"
import { AiFillCaretRight } from "react-icons/ai"

import { MENU_LIST } from "../../../components/shared/MenuMetadata"
import {
  MenuContainer,
  MainContainer,
  MenuTitle,
  HeadingWrapper,
  SubMenuListWrapper, SubMenuItemWrapper, SubMenuTitle, WrapperHeading, MenuColumn
} from "./StyledAccountMenu"

import {MainWrapper, HeadingTitleBold} from '../StyledAccount'


export default () => {

  const renderMenuItem = (menuItem) => {
    return (
      <MenuContainer key={menuItem.name}>
        <HeadingWrapper to={menuItem.link}>
          {menuItem.icon()}
          <MenuTitle>{menuItem.name}</MenuTitle>
        </HeadingWrapper>
        {menuItem.subMenuList && (
          <SubMenuListWrapper>
            {menuItem.subMenuList.map(subMenuItem =>
              <SubMenuItemWrapper key={subMenuItem.name} to={subMenuItem.link}>
                <AiFillCaretRight size={15} color={colors.darkGrey}/>
                <SubMenuTitle>{subMenuItem.name}</SubMenuTitle>
              </SubMenuItemWrapper>
            )}
          </SubMenuListWrapper>
        )}
      </MenuContainer>
    )
  }

  return (
    <MainWrapper>
      <WrapperHeading>
        <span>Bienvenue dans votre <HeadingTitleBold>espace client</HeadingTitleBold></span>
      </WrapperHeading>
      <MainContainer>
        {renderMenuItem(MENU_LIST[0])}
        <MenuColumn>
          {renderMenuItem(MENU_LIST[1])}
          {renderMenuItem(MENU_LIST[2])}
        </MenuColumn>
      </MainContainer>
    </MainWrapper>

  )
}

import React, { useEffect, useState } from "react"
import { useParams } from "@reach/router"
import { Link, navigate } from "gatsby"

import {} from "./StyledAccountProfile"

import { AiOutlineArrowLeft } from "react-icons/ai"

import {
  HeadingTitleBold,
  MainWrapper,
  ReturnMenu,
  ReturnMenuTitle, SubMenuContainer,
  WrapperHeading
} from "../StyledAccount"

import { WrapperForm, FieldWrapper, Label, ButtonSubmit, ColumnFieldMarginR, DatePicker } from "./StyledAccountProfile"
import { Autocomplete, TextInput, toaster } from "evergreen-ui"
import { attributeProfileGender } from "../../shared/AccountMetaData"
import { FaCheck } from "react-icons/fa"
import useAuth from "../../../hooks/useAuth"
import { colors } from "../../styles"
import { uuid } from "uuidv4"
import { editUserProfile } from "../../../utils/userHelpers"

export default ({currentUser, successCallback}) => {
  const [currentProfile, setCurrentProfile] = useState({
    id: "",
    gender: "",
    lastName: "",
    firstName: "",
    phone: "",
    birthday: ""
  })

  const [isError, setError] = useState({})

  useEffect(() => {
    if (currentUser.id) setCurrentProfile({
      id: currentUser.id,
      gender: currentUser.gender || '',
      lastName: currentUser.lastName || '',
      firstName: currentUser.firstName || '',
      phone: currentUser.phone || '',
      birthday: currentUser.birthday || ''
    })
  }, [currentUser])

  const handleChange = (name, value) => {
    setCurrentProfile({
      ...currentProfile,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    editUserProfile(currentProfile)
      .then(() => {
        successCallback()
      })
      .catch((err) => {
        console.error("EDIT PROFILE: ERROR ", err)
      })
  }

  const renderProfileForm = () => {
    return (
      <WrapperForm>
        <FieldWrapper>
          <ColumnFieldMarginR>
            <Label>Titre</Label>
            <Autocomplete
              onChange={e => handleChange("gender", e)}
              items={attributeProfileGender()}
              initialSelectedItem={currentProfile.gender}
            >
              {(props) => {
                const { getInputProps, getRef, inputValue, openMenu } = props
                return (
                  <TextInput
                    height={42}
                    isInvalid={!!isError.attributeType}
                    width={70}
                    value={currentProfile.gender}
                    innerRef={getRef}
                    {...getInputProps({
                      onFocus: () => {
                        openMenu()
                      }
                    })}
                  />
                )
              }}
            </Autocomplete>
          </ColumnFieldMarginR>
          <ColumnFieldMarginR>
            <Label>Nom</Label>
            <TextInput
              height={42}
              width="100%"
              isInvalid={!!isError.lastName}
              value={currentProfile.lastName}
              onChange={e => handleChange("lastName", e.target.value)}
            />
          </ColumnFieldMarginR>
          <div>
            <Label>Prénom</Label>
            <TextInput
              marginV={30}
              height={42}
              width="100%"
              isInvalid={!!isError.firstName}
              value={currentProfile.firstName}
              onChange={e => handleChange("firstName", e.target.value)}
            />
          </div>
        </FieldWrapper>
        <FieldWrapper>
          <ColumnFieldMarginR>
            <Label>Téléphone</Label>
            <TextInput
              height={42}
              width="100%"
              isInvalid={!!isError.phone}
              value={currentProfile.phone}
              onChange={e => handleChange("phone", e.target.value)}
            />
          </ColumnFieldMarginR>
          <div>
            <Label>Date de naissance</Label>
            <DatePicker
              type="date"
              name="birthday"
              value={currentProfile.birthday}
              onChange={e => handleChange("birthday", e.target.value)}
            />
          </div>
        </FieldWrapper>
        <ButtonSubmit onClick={(e) => handleSubmit(e)}>
          <FaCheck color={colors.darkWaskoll}/>
          <span style={{marginLeft: "10px"}}>Sauvegarder les modifications</span>
        </ButtonSubmit>
      </WrapperForm>

    )
  }


  return (
    <MainWrapper>
      <SubMenuContainer>
        <WrapperHeading>
          <ReturnMenu to={"/account/"}>
            <AiOutlineArrowLeft size={15} color={"black"}/>
            <ReturnMenuTitle>Revenir en arrière</ReturnMenuTitle>
          </ReturnMenu>
          <span>Modifier vos <HeadingTitleBold>informations personnelles</HeadingTitleBold></span>
        </WrapperHeading>
        {renderProfileForm()}
      </SubMenuContainer>

    </MainWrapper>
  )
}
